.page-login {
	    background-height:50%;
	    background-width:50%;	    
	    -webkit-background-size: cover;
	    -moz-background-size: cover;
	    -o-background-size: cover;
	    background-size: cover;
	    height: auto;
	    position: absolute;
	    left: 0;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    overflow: hidden;
	}
	.page-login-vertical {
	    display: block;
	    position: absolute;
	    top: 50%;
	    left: 15px;
	    right: 15px;
	    transform: translateY(-50%)
	}
	.page-login-box {
	    font-family: Raleway, sans-serif;
	    max-width: 580px;
	    margin-left: auto;
	    margin-right: auto;
	    background-color: #28bae6;
	    padding: 8px 37px
	}
	.page-login-box h1 {
	    margin-top: 0;
	    margin-bottom: 22px
	}
	.page-login-box h1 img {
	    width: 188px;
	    height: auto
	}
	.page-login-panel {
		width:100%;
	    color: #b7b7b7;
	    background-color: #fff;
	    padding: 25px 50px 20px;
	    margin-bottom: 8px
	}
	.page-login-panel input:-webkit-autofill,
	.page-login-panel input:-webkit-autofill:focus,
	.page-login-panel input:-webkit-autofill:hover {
	    -webkit-text-fill-color: #474a4c;
	    -webkit-box-shadow: 0 0 0 1000px #fff inset
	}
	.page-login-panel a {
	    color: #2377b8
	}
	.page-login-panel a:hover {
	    color: #4991c9;
	    text-decoration: none!important;
	}
	.page-login-banner a,
	.page-login-panel .form-group span.forgot_text {
	    color: #f39200
	}

	.page-login-panel,
	.page-login-panel .btn,
	.page-login-panel .form-control {
	    font-size: 16px
	}
	.page-login-panel .form-group {
	    margin-bottom: 10px
	}
	.page-login-panel .form-control {
	    padding-left: 48px;
	    height: 48px;
	}
	.page-login-panel .alert-danger{
		padding-right: 5px;
	    background-color: #fff;
	    border: 1px solid #bdbdbd;
	    padding-left: 5px;
	    border-top: 10px solid #f39200;
	    margin-bottom: -15px;
	    margin-top: 25px;
	    margin-right: 15px;
	    margin-left: 15px;
	    border-radius: 0px;
	    color: #f39200;
	    font-size: 17px;
	}
	.page-login-panel .alert-danger .close{
		color: #f39200;
	}
	.page-login-icon {
	    position: absolute;
	    left: 27px;
	    top: 25px;
	    margin-top: -15px
	}
	span.page-login-icon i {
	    margin-top: 1px;
	    font-size: 23px;
	    margin-left: 3px
	}
	.page-login-panel .page-login-actions {
	    padding-top: 12px;
	    min-height: 68px;
	    margin-bottom: 4px
	}
	page-login-icon i {
	    margin-top: 1px;
	    font-size: 23px;
	    margin-left: 3px
	}
	.page-login-panel .page-login-actions {
	    padding-top: 12px;
	    min-height: 68px;
	    margin-bottom: 4px
	}
	.page-login .btn-default {
	    border: 0;
	    border-radius: 0;
	    background-color: #1c75bb;
	    font-size: 20px;
	    color:white!important;
	}
	.page-login .btn-default:hover {
	    background-color: #4991c9!important;
	}
	.page-login .btn-default img {
	    display: inline-block;
	    vertical-align: top;
	    margin-right: 5px;
	    margin-top: -2px
	}
	.page-login .alert-danger:after{
	    display: none
	}
	.page-login-actions .checkbox {
	    padding-top: 0;
	    margin-top: -1px;
	    height: 34px
	}
	.page-login-actions input[type=checkbox] {
	    width: 14px;
	    height: 14px;
	    padding: 0;
	    margin-top: 4px;
	    margin-top: 6px\9
	}
