

.navbar-brand {
	 /* width: 104px;
	height: 42px; */
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
}



#header-navbar-nav .nav-link{
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	margin-left: 3.0rem;
}

#header-navbar-nav .nav-link.active{
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	color: #4C9FB9;

}
#header-navbar-nav .nav-link{
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	color: #292D52;

}

.headerTabs{
  background: #FFFFFF;
  box-shadow: 0px 1px 20px 1px rgba(162, 162, 162, 0.25);
	display: flex;
	justify-content: center;
}
