#root {
    /* font-family: Roboto; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 14px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.modal-header{
border-bottom: 0px;
}

.custom-control-label:before{
       height: 19px;
       width: 19px;
   }
.custom-control-label:after{
       height: 19px;
       width: 19px;
       background-color: white;
        border: 2px solid black;
   }
.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
       background-color:white;
       border: 2px solid black;

   }
 .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
     height: 19px;
     width: 19px;
    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='black' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

  }
.modal-title{
  color:#17a2b8;
  border-bottom: 3px solid #17a2b8;
  box-shadow: 0 5px 2px -2px #BEBEBE;
  padding-bottom: 5px;
}

.modal-footer{
  border-top: 0px;
}

.accountantDataCollapse{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #292D52;
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-start;
  padding-left: 13%;
  padding-top: 4%;
}

.accountantData{
  padding-top: 30px;
  padding-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
}
.accountSaveButton{
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.buttonAlignment{
  padding-left: 0px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.ButtonAdd{

  border-color: silver;
  color: #17a2b8;
  font-weight: bold;
  font-size: 14px;
  width: 110px;
  height: 34px;
  left: 0px;
  top: 0px;
  border: 2px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 27px;

}
.form-control-Modal-Expense  div.css-yk16xz-control {
  border-radius: 65px / 65px;
}
.form-control-Modal-Expense div.css-1pahdxg-control {
  border-radius: 65px / 65px;
}
.form-control-Modal-Revenue  div.css-yk16xz-control {
  border-radius: 65px / 65px;
}
.form-control-Modal-Revenue div.css-1pahdxg-control {
  border-radius: 65px / 65px;
}
.ButtonAddAccountant{
  border-color: silver;
  color: #17a2b8;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.5rem;
  margin-top: 1.0rem;
  border: 2px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 27px;
  width: 110px;
  height: 34px;
}

 .form-control-Modal-Expense .form-control {
 border-radius: 65px / 65px!important;
  text-align: center;
}
 .form-control-Modal-Revenue .form-control {
 border-radius: 65px / 65px!important;
  text-align: center;
}
 .form-control-Modal-Accountant .form-control {
 border-radius: 65px / 65px!important;
  text-align: center;
}
 .accountantData .form-control {
 border-radius: 65px / 65px!important;
  text-align: center;
}

.form-control-Modal-Expense .form-control:focus {
    color: #474a4c!important;
    border-color: #7fb5d4!important;
    outline: 0!important;
    -webkit-border-radius: 65px / 65px!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.form-control-Modal-Revenue .form-control .form-control:focus {
    color: #474a4c!important;
    border-color: #7fb5d4!important;
    outline: 0!important;
    -webkit-border-radius: 65px / 65px!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.form-control-Modal-Accountant .form-control .form-control:focus {
    color: #474a4c!important;
    border-color: #7fb5d4!important;
    outline: 0!important;
    -webkit-border-radius: 65px / 65px!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.form-control[readonly] {
  background-color: unset;
}
.page-header h1>.btn,
.page-header h1>.btn-group,
.page-header h2>.btn,
.page-header h2>.btn-group {
    margin-left: 5px;
    margin-top: -2px
}
.page-header {
    padding: 0;
    margin-top: 16px;
    margin-bottom: 8px;
    border: 0
}
.page-header h1,
.page-header h2 {
    line-height: 31px;
    margin-top: 0;
    margin-bottom: 0
}
.page-header h1 {
    font-size: 18px;
    font-weight: 400
}
.table>tbody>.highlight:hover, .table>tbody>tr:hover, .table_row:hover, ul.table>li:hover {
    background-color: #f2f2f2!important;
    -webkit-transition: background-color .2s linear;
    -moz-transition: background-color .2s linear;
    -ms-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
    transition: background-color .2s linear;
}



.table>thead>tr>th{
    font-weight: 400;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 2%;
    color: #bdbdbd;
    font-weight:bold;
    font:caption;
    padding-left: 4px;
    padding-right: 4px;
    border-color: #dfe1e5;
    vertical-align: bottom;
    border: 0px;

}
.table>tbody>tr>td{
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 0;
    border-bottom: 1px solid #dfe1e5;
    padding-left: 4px;
    padding-right: 4px;
    border-color: #dfe1e5;
}
.active_rows>tbody>tr, .active_rows>tbody>tr:hover, .sortable_row {
    cursor: pointer;
}
.table>tbody>tr:hover>td, .table_row:hover, ul.table>li:hover {
    border-color: #adbcc3;
    -webkit-transition: border-color .2s linear;
    -moz-transition: border-color .2s linear;
    -ms-transition: border-color .2s linear;
    -o-transition: border-color .2s linear;
    transition: border-color .2s linear;
}
.dropdown .btn-sm{
    line-height:0.3;
}
.dropdown .btn-sm{
    padding: 0.25rem 0.3rem;
    background-color: #fff;
}
.dropdown .btn,.dropdown .btn:hover{
    border-color: #cecece;
    background-color: #fff;
}
.dropdown .btn:focus,.dropdown .btn:active{
    color: #5d97b9;
    background-color: #fff;
    border-color: #adadad;
}
.dropdown.show .btn.dropdown-toggle {
    background-color: #fff!important;
    border-color: #adadad!important;
}
.navbar.bg-light{
    background-color:#fff!important;
}
.page-item.active .page-link {
    color: #8e9599!important;
    background-color: #dfe1e5!important;
    border-color: #dfe1e5!important;
}
.page-item .page-link{
    color: #8e9599!important;
}
.page-item:not(.active):hover .page-link{
    cursor: pointer;
}
.pagination{
    display: inline-flex;
    float: left;
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
}
.pagination li>a, .pagination li>span {
    margin-left: 4px;
    -webkit-transition: color .25s linear, border-color .25s linear;
    -moz-transition: color .25s linear, border-color .25s linear;
    -ms-transition: color .25s linear, border-color .25s linear;
    -o-transition: color .25s linear, border-color .25s linear;
    transition: color .25s linear, border-color .25s linear;
}
.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;

}
.dropZone_container{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  cursor: pointer;
}
.dropZone_container p{
  margin-top:unset;
  margin-bottom:unset;
}
.dropZoneListThumb{
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}
.photosListThumb{
  position: relative;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-top: 12px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 320px;
  height: 320px;
  padding: 4px;
  box-sizing: border-box;
}
.thumbInner{
  display: flex;
  min-width: 0px;
  overflow: hidden;
  margin:0 auto!important;
}
.thumbInner img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner_update{
  bottom: 0;
  right: 0;
}
.ft-16{
  font-size:16px;
  font-weight: bold;
  color: #989898;
}
.ft-15{
  font-size: 15px;
  font-weight: bold;
  color: black;
}
.p-4{
  padding-left: 0rem!important;
  padding-right: 0rem!important;
}
.accountCheckbox{
  font-weight: normal;
  color: #BEBEBE;
}

.ft-11{
  font-size:11px;
}
.border-error,.selectize.border-error div:first-child{
  border-color:#dc3545;
}
.ft-30{
  font-size:30px;
}
.pointer{
  cursor: pointer;
}
.alert-fixed,.alert-fixed-modal {
  position: fixed;
  z-index: 50;
  margin: 0 15px;
}
.alert-fixed{
  right: 0;
}

.react-confirm-alert-overlay{
  z-index: 9999!important;
}
.width_100{
  width: 100%;
}
.zi-10{
  z-index:10;
}

.navbartabs {
  display: flex;
  justify-content: center;
  border: 0px;
}

#financial_tabs-tab-expenses{
  color: #292D52;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  border: 0px;
  margin-left: 2.5rem;
}

  #financial_tabs-tab-expenses.nav-item.nav-link.active{
  color: #4C9FB9;
  border-bottom: 2px solid #4C9FB9;

}

#financial_tabs-tab-revenues.nav-item.nav-link.active{
  color: #4C9FB9;
  border: 0px;
  border-bottom: 2px solid #4C9FB9;
}
#financial_tabs-tab-accountant.nav-item.nav-link.active{
  color: #4C9FB9;
  border: 0px;
  border-bottom: 2px solid #4C9FB9;
}


#financial_tabs-tab-revenues{
  color: #292D52;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  border: 0px;
  margin-left: 2.5rem;
}
#financial_tabs-tab-accountant{
  color: #292D52;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin-left: 2.5rem;
  border: 0px;
}

.navbarContent{
  margin-top: 2.5rem;
}
