.btn-group-calendar,
.page-header .btn-group-calendar.btn-group {
    text-align: center;
    z-index: auto
}
.btn-square {
    -webkit-border-radius: 0;
    border-radius: 0
}
.btn-group-calendar .calendar-date {
    float: left;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    background-color: #f5f5f5;
    text-align: center;
    color: #5f6366;
    line-height: 29.5px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 80px;
    min-height: 31px
}
.btn-group-calendar .btn {
    padding-left: 0;
    padding-right: 0;
    text-align: center
}
button.btn.btn-square.btn-plain {
    width: 32px
}
.planning{
    width:100%;
}
.planning_month .planning_body {
    height: auto;
    overflow: visible
}
.planning_month .planning_body:after,
.planning_month .planning_body:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #e0e0e0
}
.planning_month .planning_body:before {
    left: auto;
    right: 0
}
.planning_month .planning_cell {
    background-color: #fff;
    color: #8e9599;
    text-align: left;
    height: 170px;
    position: relative
}
.planning_month .planning_empty {
    background-color: #f5f5f5
}
.planning_month .planning_details {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px
}
.planning_month .planning_details_btn,
.planning_month .planning_details_btn:focus,
.planning_month .planning_details_btn:hover {
    display: block;
    font-size: 14px;
    color: #bdbdbd
}
.planning_month .planning_details_btn:hover {
    color: #8e9599
}
.planning_header {
    background-color: #fff;
    border-bottom: 2px solid #dfe1e5
}
.planning_cell_wrap,
.planning_header .small {
    width: 14.2857%
}
.planning_header .small {
    border-bottom: 1px solid;
    border-left: 1px solid;
    color: #bdbdbd;
    min-height: 0;
    padding: 5px 0;
    text-align: center;
    font-size: 11px;
    line-height: 1.42857143;
    border-color: #fff
}
.planning_header .small:first-child {
    border-left: 0
}
.planning_body {
    position: relative;
    height: 546px;
    overflow: hidden
}
.planning_cell_wrap {
    border-left: 1px solid #e0e0e0
}
.planning_cell_wrap:first-child {
    border-left: 0
}
.planning_cell_wrap.selected{
    border: 2px solid black!important;
}
.planning_cell_wrap.preselected .planning_cell{
    background-color: #D9F6FB;
}
.planning_cell {
    background-color: #f5f5f5;
    color: #b8b8b8;
    text-align: right;
    line-height: 14px;
    padding: 12px 10px;
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    cursor: pointer;
}
.planning_cell_wrap:not(.selected) .planning_cell:hover{
    border: 2px solid black!important;
}
.planning_cell_wrap.selected .planning_cell{
    height:166px;
}
.planning_cell_wrap.selected .planning_cell:hover{
    cursor:unset;
}
.planning_cell:first-child {
    border-left: 0
}
.planning_cell .planning_entry:hover {
    opacity: .7
}
.planning_cell .text-ellipsis span {
    padding-left: 3px
}
.planning_cell i.fa-times{
    position: absolute;
    font-size: 130px;
    margin-left: 10px;
}
.planning_cell .planning_price{
    position: absolute;
    right: 5px;
    bottom: 5px;
}
.planning_cell_wrap:not(.preselected) .planning_cell.blocked{
    background-color: #f5f5f5;
}
.planning_empty .planning_date {
    color: #bdbdbd
}
.calendar_options{
    margin-top: 200px;
}
@media screen and (max-width: 576px) {
  .calendar_options {
    margin-top: 0px;
  }
}